<template transition="scale-transition" origin="center center">
  <v-container id="driver_vehicle" fluid fill-height>
    <v-row justify="center">
      <v-col cols="12" class="pt-0">
        <DataTable
          ref="DriverVehicle"
          :headers="headers"
          :title="$t('views.settings.driver_vehicle')"
          name="DriverVehicle"
          :url="url"
          :height="600"
          secondary
          class="pa-0"
          newOn
          updateOn
          searchOn
          :urlCount="urlCount"
          actionsOn
          @newRecord="onNewRecordTable()"
          @uploadFile="onUploadFile()"
        >
          <template #[`item.options`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="editRecordTable(item)"
                >
                  <v-icon small> fa-solid fa-edit </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("components.views.settings.driver_vehicle.driver_vehicle.edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  text
                  color="red darken-2"
                  v-on="on"
                  @click="deleteItem(item.id)"
                >
                  <v-icon small> fa-solid fa-trash </v-icon>
                </v-btn>
              </template>
              <span>{{
                $t("components.views.settings.driver_vehicle.driver_vehicle.delete")
              }}</span>
            </v-tooltip>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogNew" scrollable width="50%" persistent>
      <ModalFormDriverVehicle
        @closeForm="onCloseForm()"
        @reloadTable="onRealoadTable()"
        :key="componentKey"
        :edit="edit"
        :data="itemUnit"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ModalFormDriverVehicle: () =>
      import(
        /* webpackChunkName: "ModalFormDriverVehicle" */ "@/components/views/Settings/DriverVehicle/Modals/ModalFormDriverVehicle/ModalFormDriverVehicle.vue"
      ),
  },
  data() {
    return {
      componentKey: 0,
      dialogNew: false,
      driver: {},
      vehicle: {},
      itemUnit: {},
      edit: false,
    };
  },
  name: "DriverVehicle",
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    idCompany() {
      let userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    url() {
      return `/VehicleDriver/GetByIdCompanyAsync`;
    },
    urlCount() {
      return `/VehicleDriver/GetByIdCompanyCountAsync`;
    },
    urlUpload() {
      return `/VehicleDriver/ImportFileExcel/${this.idCompany}`;
    },
    searchField() {
      return this.search;
    },
    headers() {
      return [
        {
          text: this.$t("components.views.settings.driver_vehicle.driver_vehicle.driver"),
          class: "text-center",
          align: "center",
          value: "driver.identification_driver",
        },
        {
          text: this.$t("components.views.settings.driver_vehicle.driver_vehicle.names_last_names"),
          class: "text-center",
          align: "center",
          value: "driver.name_driver",
        },
        {
          text: this.$t("components.views.settings.driver_vehicle.driver_vehicle.telephone"),
          class: "text-center",
          align: "center",
          value: "driver.phone_driver",
        },
        {
          text: this.$t("components.views.settings.driver_vehicle.driver_vehicle.license_plate"),
          class: "text-center",
          align: "center",
          value: "vehicle",
        },
        {
          text: this.$t(
            "components.views.settings.driver_vehicle.driver_vehicle.company_transporter_name"
          ),
          class: "text-center",
          align: "center",
          value: "transporter.company_transporter",
        },
      ];
    },
  },
  metaInfo() {
    return {
      title: this.$t("views.settings.driver_vehicle"),
    };
  },
  mounted() {
    if (this.typeUser == 2) {
      this.$router.push({ name: "Error" });
    }
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    onNewRecordTable() {
      this.componentKey += 1;
      this.dialogNew = true;
    },

    editRecordTable(item) {
      this.itemUnit = Object.assign({}, item);
      this.edit = true;
      this.componentKey += 1;
      this.dialogNew = true;
    },

    async deleteItem(idVehicleDriver) {
      if (
        await this.$root.$confirm(
          this.$t(
            "components.views.settings.driver_vehicle.driver_vehicle.eliminate_relationship_between_vehicle_and_driver"
          ),
          this.$t(
            "components.views.settings.driver_vehicle.driver_vehicle.are_you_sure_you_want_to_delete_this_record"
          ),
          "eliminar"
        )
      ) {
        this.showLoader({
          show: true,
        });

        this.axios
          .delete(`/VehicleDriver/Delete`, {
            params: {
              idVehicleDriver,
            },
          })
          .then(() => {
            this.showSnack({
              text: this.$t(
                "components.views.settings.driver_vehicle.driver_vehicle.driver_vehicle_delete_success"
              ),
              title: this.$t("components.views.settings.driver_vehicle.driver_vehicle.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: this.$t(
                "components.views.settings.driver_vehicle.driver_vehicle_delete_error"
              ),
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
            this.onRealoadTable();
          });
      }
    },
    onCloseForm() {
      this.$nextTick(() => {
        this.itemUnit = Object.assign({}, {});
      });
      this.edit = false;
      this.dialogNew = false;
    },

    onRealoadTable() {
      this.$refs.DriverVehicle.loadDataNew();
    },
  },
};
</script>
